import type { ModalProps } from "~/components/modal";
import type { ModalDocumentPickerQuery } from "~/types/api";
import { useState, useCallback } from "react";
import Alert from "~/components/alert";
import Button from "~/components/button";
import ButtonCloseModal from "~/components/button-close-modal";
import DocumentList from "~/components/documents/list";
import { IconLoading } from "~/components/icons";
import Modal from "~/components/modal";
import SearchFilter from "~/components/search-filter";
import {
  useOptionalContentTopic,
  useOptionalEmployee,
  useOptionalProject
} from "~/contexts";
import { plural } from "~/utils/formatting";
import { useFetcherData } from "~/utils/remix";

type MultiProps = ModalProps & {
  addLabel?: undefined;
  disabledIds: string[];
  multiple: true;
  onAdd: (ids: string[]) => void;
  pdfWarning?: boolean;
  projectNumbers?: string[];
};

type SingleProps = ModalProps & {
  addLabel?: string;
  disabledIds?: undefined;
  multiple?: false;
  onAdd: (value: unknown) => void;
  pdfWarning?: boolean;
  projectNumbers?: string[];
};

export default function ModalDocumentChooser({
  addLabel,
  disabledIds,
  multiple,
  onAdd,
  onClose,
  pdfWarning,
  projectNumbers
}: SingleProps | MultiProps) {
  const project = useOptionalProject();
  const employee = useOptionalEmployee();
  const contentTopic = useOptionalContentTopic();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [query, setQuery] = useState("");
  const fetcher = useFetcherData<ModalDocumentPickerQuery>(
    query ? "/resources/documents/modal-picker" : undefined,
    {
      projectNumbers: projectNumbers,
      projectNumber: project?.number,
      employeeId: employee?.id,
      employeeScope: employee?.scope,
      contentTopicId: contentTopic?.id,
      query
    }
  );

  const close = useCallback(() => {
    setSelectedIds([]);
    onClose();
  }, [onClose]);

  const handleAdd = () => {
    if (multiple) {
      onAdd(selectedIds);
      close();
    }
  };

  return (
    <Modal onClose={close} size="Large">
      <Modal.Header title="Add Files" />
      <Modal.Body>
        <SearchFilter
          onSearch={setQuery}
          autoFocus
          width="100%"
          placeholder="Search..."
        />
      </Modal.Body>
      {!query ? (
        <Alert>
          For performance reasons, the full list cannot be displayed here.
          Please search for something above.
        </Alert>
      ) : fetcher.state !== "idle" || !fetcher.data ? (
        <Modal.Body>
          <IconLoading />
        </Modal.Body>
      ) : fetcher.data.documents.length ? (
        <>
          <DocumentList
            documents={fetcher.data.documents}
            disabledIds={disabledIds}
            minimal
            nested
            expandAll
            viewOptions={{
              hideSummaries: true,
              pdfWarning
            }}
            {...(multiple
              ? { selectedIds, setSelectedIds }
              : { onAdd, addLabel })}
          />
        </>
      ) : (
        <Alert mode="warning">No results</Alert>
      )}

      <Modal.Footer>
        <ButtonCloseModal />
        <Button
          mode="primary"
          disabled={!selectedIds.length}
          onClick={handleAdd}
        >
          Add {!!selectedIds.length && plural("File", selectedIds.length, true)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
