import type { Fetcher } from "@remix-run/react";
import type { ModalProps } from "~/components/modal";
import type { ErrorsWithChangeset } from "~/components/remix-form";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Button from "~/components/button";
import ButtonCloseModal from "~/components/button-close-modal";
import Modal from "~/components/modal";
import {
  Errors,
  RemixForm,
  Input,
  fetcherFailed
} from "~/components/remix-form";

interface Props extends ModalProps {
  onConfirm: () => Promise<unknown>;
  message: string | JSX.Element;
  confirm?: boolean;
  error?: ErrorsWithChangeset;
  confirmText?: string;
  processingText?: string;
  fetcher?: Fetcher;
}

export default function ConfirmDelete({
  onConfirm,
  onClose,
  message,
  confirmText = "DELETE",
  confirm = true,
  processingText = "Deleting...",
  fetcher,
  error
}: Props) {
  const [deleting, setDeleting] = useState(false);
  const [errors, setErrors] = useState<ErrorsWithChangeset>([]);
  // Errors from Remix forms
  useEffect(() => {
    if (fetcher && fetcherFailed(fetcher) && deleting) {
      setDeleting(false);
      setErrors(fetcher?.data.errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher?.state, deleting]);

  const [text, setText] = useState("");
  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (text === confirmText || !confirm) {
      setDeleting(true);
      onConfirm();
    }
  };

  useEffect(() => {
    if (deleting && error) {
      setDeleting(false);
    }
  }, [deleting, error]);

  const ref = useRef<HTMLInputElement>(null);
  return (
    <Modal onExplicitClose={onClose} initialFocus={ref}>
      <RemixForm onSubmit={submit} prompt={false}>
        <Modal.Header title="Confirm" />
        <Modal.Body>
          <Errors errors={errors} />
          {_.isString(message) ? <p>{message}</p> : message}
          {confirm && (
            <>
              <hr />
              <p>Please type &quot;{confirmText}&quot; below to confirm.</p>
              <Input
                disabled={deleting}
                name="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                autoFocus
                noLabel
                forwardRef={ref}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonCloseModal>Cancel</ButtonCloseModal>
          <Button
            type="submit"
            mode="danger"
            disabled={(confirm && text !== confirmText) || deleting}
          >
            {deleting
              ? processingText
              : confirm
                ? `I understand, ${confirmText.toLowerCase()} it`
                : _.startCase(confirmText.toLowerCase())}
          </Button>
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
}
