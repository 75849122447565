import type { CSSProperties, InputHTMLAttributes, ReactNode } from "react";
import type { TextareaAutosizeProps } from "react-textarea-autosize";
import type {
  AddressPickerMultiProps,
  AddressPickerSingleProps
} from "~/components/addresses/picker";
import type { PracticeAreaPickerProps } from "~/components/admin/practice-area-picker";
import type { AuditTemplateItemPickerProps } from "~/components/audits/template-item-picker";
import type { AuditTemplatePickerProps } from "~/components/audits/template-picker";
import type { BillingPositionPickerProps } from "~/components/billing-positions/picker";
import type { ButtonSelectProps } from "~/components/button-select";
import type {
  CampaignFieldPickerPropsMulti,
  CampaignFieldPickerPropsSingle
} from "~/components/campaigns/field-picker";
import type {
  ComboBoxParentPropsMultiple,
  ComboBoxParentPropsSingle,
  ComboBoxPropsMultiple,
  ComboBoxPropsSingle
} from "~/components/combobox";
import type { ContactPickerProps } from "~/components/contacts/picker";
import type { ContentTopicPickerProps } from "~/components/content/picker";
import type { DateFieldProps } from "~/components/date-field";
import type { DelayedTextInputProps } from "~/components/delayed-text-input";
import type { DeliverablePickerProps } from "~/components/deliverables/picker";
import type { LibrarySelectProps } from "~/components/documents/library-select";
import type { DocumentPickerProps } from "~/components/documents/picker";
import type { ModalDocumentPickerProps } from "~/components/documents/picker-modal";
import type { SectionComboProps } from "~/components/documents/section-picker";
import type {
  ElementPickerPropsMulti,
  ElementPickerPropsSingle
} from "~/components/elements/picker";
import type { EmployeePickerProps } from "~/components/employees/picker";
import type { FeaturePickerProps } from "~/components/features/picker";
import type { FilePickerProps } from "~/components/file-picker";
import type { FileUploaderProps } from "~/components/file-uploader";
import type {
  IssuePickerPropsMulti,
  IssuePickerPropsSingle
} from "~/components/issues/picker";
import type { ListItemPickerProps } from "~/components/lists/item-picker";
import type {
  LocationPickerPropsMulti,
  LocationPickerPropsSingle
} from "~/components/locations/picker";
import type { MembershipPickerProps } from "~/components/memberships/picker";
import type { NumberInputProps } from "~/components/number-input";
import type { OfficePickerProps } from "~/components/offices/picker";
import type { PayPeriodPickerProps } from "~/components/pay-periods/picker";
import type { PhotosetPickerProps } from "~/components/photos/inspection-picker";
import type { PhotoPickerProps } from "~/components/photos/picker";
import type { PhysicalAddressPickerProps } from "~/components/physical-addresses/picker";
import type { MilestoneParentPickerProps } from "~/components/plans/milestone-parent-picker";
import type {
  PlayerPickerPropsMulti,
  PlayerPickerPropsSingle
} from "~/components/players/picker";
import type {
  ProjectPickerPropsMulti,
  ProjectPickerPropsSingle
} from "~/components/projects/picker";
import type { QuarterPickerProps } from "~/components/quarter-picker";
import type { SlateEditorProps } from "~/components/rich-editor";
import type { SalesStagePickerProps } from "~/components/sales/stage-picker";
import type { SKUPickerProps } from "~/components/skus/picker";
import type { SocialMediaPostPickerProps } from "~/components/social-media/post-picker";
import type {
  TagPickerMultiProps,
  TagPickerSingleProps
} from "~/components/tags/picker";
import type { TenTouchItemPickerProps } from "~/components/ten-touches/picker";
import type {
  TenTouchTypePickerPropsMulti,
  TenTouchTypePickerPropsSingle
} from "~/components/ten-touches/type-picker";
import type {
  TestingPickerPropsMulti,
  TestingPickerPropsSingle
} from "~/components/testing/picker";
import type { TrainingPickerProps } from "~/components/training/picker";
import type {
  UserPickerPropsMulti,
  UserPickerPropsSingle
} from "~/components/users/picker";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useContext } from "react";
import DepositPicker from "~/components/accounting/deposit-picker";
import RetainerInvoicePicker from "~/components/accounting/retainer-invoice-picker";
import AddressPicker from "~/components/addresses/picker";
import PracticeAreaPicker from "~/components/admin/practice-area-picker";
import AgreementPicker from "~/components/agreements/picker";
import AuditTemplateItemPicker from "~/components/audits/template-item-picker";
import AuditTemplatePicker from "~/components/audits/template-picker";
import BillingPositionPicker from "~/components/billing-positions/picker";
import ButtonSelect from "~/components/button-select";
import CampaignFieldPicker from "~/components/campaigns/field-picker";
import CandidatePicker from "~/components/candidates/picker";
import ComboBox from "~/components/combobox";
import CommunityPicker from "~/components/communities/picker";
import AffiliationTypePicker from "~/components/contacts/affiliation-picker";
import JobTitlePicker from "~/components/contacts/job-title-picker";
import MarketingStatusPicker from "~/components/contacts/marketing-status-picker";
import ContactPicker from "~/components/contacts/picker";
import ContentTopicPicker from "~/components/content/picker";
import DateField from "~/components/date-field";
import DelayedTextInput from "~/components/delayed-text-input";
import DeliverablePicker from "~/components/deliverables/picker";
import DispatchPicker from "~/components/dispatches/picker";
import AuthorCombo from "~/components/documents/author-combo";
import DocumentCategoryPicker from "~/components/documents/category-picker";
import LibrarySelect from "~/components/documents/library-select";
import DocumentPicker from "~/components/documents/picker";
import ModalDocumentPicker from "~/components/documents/picker-modal";
import SectionCombo from "~/components/documents/section-picker";
import ElementPicker from "~/components/elements/picker";
import EmployeePicker from "~/components/employees/picker";
import FeaturePicker from "~/components/features/picker";
import FilePicker from "~/components/file-picker";
import FileUploader from "~/components/file-uploader";
import InputGroup from "~/components/input-group";
import IssuePicker from "~/components/issues/picker";
import KpiSetPicker from "~/components/kpis/set-picker";
import ListItemPicker from "~/components/lists/item-picker";
import LocationPicker from "~/components/locations/picker";
import MeetingPicker from "~/components/meetings/picker";
import MembershipPicker from "~/components/memberships/picker";
import NumberInput from "~/components/number-input";
import OfficePicker from "~/components/offices/picker";
import PayPeriodPicker from "~/components/pay-periods/picker";
import InspectionPicker from "~/components/photos/inspection-picker";
import PhotoPicker from "~/components/photos/picker";
import PhysicalAddressPicker from "~/components/physical-addresses/picker";
import MilestoneParentPicker from "~/components/plans/milestone-parent-picker";
import PlanTypePicker from "~/components/plans/plan-type-picker";
import PlayerGroupPicker from "~/components/players/group-picker";
import PlayerPicker from "~/components/players/picker";
import ProjectPicker from "~/components/projects/picker";
import PTOPicker from "~/components/pto-picker";
import QuarterPicker from "~/components/quarter-picker";
import FeeSchedulePicker from "~/components/rates/fee-schedule-picker";
import Slate from "~/components/rich-editor";
import SalesStagePicker from "~/components/sales/stage-picker";
import SkillPicker from "~/components/skills/picker";
import SKUPicker from "~/components/skus/picker";
import SocialMediaAccountPicker from "~/components/social-media/account-picker";
import SocialMediaPostPicker from "~/components/social-media/post-picker";
import StatePicker from "~/components/state-picker";
import TagPicker from "~/components/tags/picker";
import SectionPicker from "~/components/tags/section-picker";
import TenTouchItemPicker from "~/components/ten-touches/picker";
import TenTouchTypePicker from "~/components/ten-touches/type-picker";
import TestingPicker from "~/components/testing/picker";
import WrappedTextarea from "~/components/textarea";
import TimePicker from "~/components/time-picker";
import TrainingCategoryPicker from "~/components/training/category-picker";
import TrainingPicker from "~/components/training/picker";
import TrainingPlanPicker from "~/components/training/plan-picker";
import UserPicker from "~/components/users/picker";
import { RemixFormContext } from "~/contexts";

type BaseProps = {
  name: string;
  allowPasswordManagers?: boolean;
  label?: ReactNode;
  rightLabel?: ReactNode;
  noLabel?: true;
  autoFocus?: boolean;
  help?: ReactNode;
  helpAfter?: ReactNode;
  inline?: true;
  forwardRef?: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | null
  >;
  buttons?: ReactNode;
  labels?: ReactNode;
  inputKey?: number | string;
  wrapperStyle?: CSSProperties;
  wrapperClassName?: string;
  noHiddenField?: boolean;
};

type InputAffiliationType = BaseProps &
  ComboBoxParentPropsSingle & { type: "affiliationType" };
type InputAddress = BaseProps & AddressPickerSingleProps & { type: "address" };
type InputAddressMulti = BaseProps &
  Omit<AddressPickerMultiProps, "multiple"> & { type: "addressMulti" };
type InputAgreement = BaseProps &
  ComboBoxParentPropsSingle & { type: "agreement" };
type InputAuditTemplate = BaseProps &
  AuditTemplatePickerProps & { type: "auditTemplate" };
type InputAuditTemplateItem = BaseProps &
  AuditTemplateItemPickerProps & { type: "auditTemplateItem" };
type InputAuthorCombo = BaseProps &
  ComboBoxParentPropsSingle & { type: "fileAuthor" };
type InputBillingPosition = BaseProps &
  BillingPositionPickerProps & { type: "billingPosition" };
type InputButtonSelect = BaseProps &
  ButtonSelectProps & { type: "button-select" };
type InputCampaignField = BaseProps &
  CampaignFieldPickerPropsSingle & { type: "campaignField" };
type InputCampaignFieldMulti = BaseProps &
  Omit<CampaignFieldPickerPropsMulti, "multiple"> & {
    type: "campaignFieldMulti";
  };
type InputCandidate = BaseProps &
  ComboBoxParentPropsSingle & { type: "candidate" };
type InputCheckbox = BaseProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, "type"> & {
    type: "checkbox";
    inline: true;
  };
type InputCommunity = BaseProps &
  ComboBoxParentPropsSingle & { type: "community" };
type InputContact = BaseProps & ContactPickerProps & { type: "contact" };
type InputCompany = BaseProps & ContactPickerProps & { type: "company" };
type InputContactOrCompany = BaseProps &
  ContactPickerProps & { type: "contactOrCompany" };
type InputContentTopic = BaseProps &
  ContentTopicPickerProps & { type: "contentTopic" };
type InputDate = BaseProps & DateFieldProps & { type: "date" };
type InputDelayedText = BaseProps &
  DelayedTextInputProps & { type: "delayedText" };
type InputDeliverable = BaseProps &
  DeliverablePickerProps & { type: "deliverable" };
type InputDeposit = BaseProps & ComboBoxParentPropsSingle & { type: "deposit" };
type InputRetainerInvoice = BaseProps &
  ComboBoxParentPropsSingle & { type: "retainerInvoice" };
type InputDispatch = BaseProps &
  ComboBoxParentPropsSingle & { type: "dispatch" };
type InputDocument = BaseProps & DocumentPickerProps & { type: "document" };
type InputModalDocument = BaseProps &
  ModalDocumentPickerProps & {
    type: "modal-document";
  };
type InputDocumentCategory = BaseProps &
  ComboBoxParentPropsSingle & { type: "documentCategory" };
type InputDocumentCategoryMulti = BaseProps &
  Omit<ComboBoxParentPropsMultiple, "multiple"> & {
    type: "documentCategoryMulti";
  };
type InputElement = BaseProps & ElementPickerPropsSingle & { type: "element" };
type InputElementMulti = BaseProps &
  Omit<ElementPickerPropsMulti, "multiple"> & { type: "elementMulti" };
type InputEmployee = BaseProps & EmployeePickerProps & { type: "employee" };
type InputFeature = BaseProps & FeaturePickerProps & { type: "feature" };
type InputFeeSchedule = BaseProps &
  ComboBoxParentPropsSingle & { type: "feeSchedule" };
type InputFile = BaseProps & FilePickerProps & { type: "file" };
type InputIssue = BaseProps & IssuePickerPropsSingle & { type: "issue" };
type InputIssueMulti = BaseProps &
  Omit<IssuePickerPropsMulti, "multiple"> & { type: "issueMulti" };
type InputJobTitle = BaseProps &
  ComboBoxParentPropsSingle & { type: "jobTitle" };
type InputKpiSet = BaseProps & ComboBoxParentPropsSingle & { type: "kpiSet" };
type InputLibrary = BaseProps & LibrarySelectProps & { type: "library" };
type InputListItem = BaseProps & ListItemPickerProps & { type: "listItem" };
type InputLocation = BaseProps &
  LocationPickerPropsSingle & { type: "location" };
type InputLocationMulti = BaseProps &
  Omit<LocationPickerPropsMulti, "multiple"> & { type: "locationMulti" };
type InputMarketingStatus = BaseProps &
  ComboBoxParentPropsSingle & { type: "marketingStatus" };
type InputMeeting = BaseProps & ComboBoxParentPropsSingle & { type: "meeting" };
type InputMembership = BaseProps &
  MembershipPickerProps & { type: "membership" };
type InputMilestoneParent = BaseProps &
  MilestoneParentPickerProps & { type: "milestoneParent" };
type InputNumber = BaseProps & NumberInputProps & { type: "number" };
type InputOffice = BaseProps & OfficePickerProps & { type: "office" };
type InputPayPeriod = BaseProps & PayPeriodPickerProps & { type: "payPeriod" };
type InputPhoto = BaseProps & PhotoPickerProps & { type: "photo" };
type InputPhotoset = BaseProps & PhotosetPickerProps & { type: "photoset" };
type InputPhysicalAddress = BaseProps &
  PhysicalAddressPickerProps & { type: "physicalAddress" };
type InputPlanType = BaseProps &
  ComboBoxParentPropsSingle & { type: "planType" };
type InputPlayer = BaseProps & PlayerPickerPropsSingle & { type: "player" };
type InputPlayerMulti = BaseProps &
  Omit<PlayerPickerPropsMulti, "multiple"> & { type: "playerMulti" };
type InputPlayerGroup = BaseProps &
  ComboBoxParentPropsSingle & { type: "playerGroup" };
type InputPracticeArea = BaseProps &
  PracticeAreaPickerProps & { type: "practiceArea" };
type InputProject = BaseProps & ProjectPickerPropsSingle & { type: "project" };
type InputProjectMulti = BaseProps &
  Omit<ProjectPickerPropsMulti, "multiple"> & { type: "projectMulti" };
type InputPtoPlan = BaseProps & ComboBoxParentPropsSingle & { type: "pto" };
type InputQuarter = BaseProps & QuarterPickerProps & { type: "quarter" };
type InputSalesStage = BaseProps &
  SalesStagePickerProps & { type: "salesStage" };
type InputSectionPicker = BaseProps &
  SectionComboProps & { type: "fileSection" };
type InputSkillPicker = BaseProps &
  ComboBoxParentPropsSingle & { type: "skill" };
type InputCombo = BaseProps & ComboBoxPropsSingle & { type: "combo" };
type InputComboMulti = BaseProps &
  Omit<ComboBoxPropsMultiple, "multiple"> & { type: "combo-multi" };
type InputSKUPicker = BaseProps & SKUPickerProps & { type: "sku" };
type InputSocialMediaAccount = BaseProps &
  ComboBoxParentPropsSingle & { type: "socialMediaAccount" };
type InputSocialMediaPost = BaseProps &
  SocialMediaPostPickerProps & { type: "socialMediaPost" };
type InputSlate = BaseProps & SlateEditorProps & { type: "slate" };
type InputState = BaseProps & ComboBoxParentPropsSingle & { type: "state" };
type InputTag = BaseProps & TagPickerSingleProps & { type: "tag" };
type InputTagMulti = BaseProps &
  Omit<TagPickerMultiProps, "multiple"> & { type: "tagMulti" };
type InputTagSection = BaseProps &
  ComboBoxParentPropsSingle & { type: "tagSection" };
type InputTenTouchItem = BaseProps &
  TenTouchItemPickerProps & { type: "tenTouchItem" };
type InputTenTouchType = BaseProps &
  TenTouchTypePickerPropsSingle & { type: "tenTouchType" };
type InputTenTouchTypeMulti = BaseProps &
  Omit<TenTouchTypePickerPropsMulti, "multiple"> & {
    type: "tenTouchTypeMulti";
  };
type InputTestLocation = BaseProps &
  TestingPickerPropsSingle & { type: "testLocation" };
type InputTestLocationMulti = BaseProps &
  Omit<TestingPickerPropsMulti, "multiple"> & { type: "testLocationMulti" };
type InputText = BaseProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, "type"> & { type?: "text" };
type InputPassword = BaseProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, "type"> & { type: "password" };
type InputTextarea = BaseProps & TextareaAutosizeProps & { type: "textarea" };
type InputTime = BaseProps & ComboBoxParentPropsSingle & { type: "time" };
type InputTrainingCategory = BaseProps &
  ComboBoxParentPropsSingle & { type: "trainingCategory" };
type InputTrainingCategoryMulti = BaseProps &
  Omit<ComboBoxParentPropsMultiple, "multiple"> & {
    type: "trainingCategoryMulti";
  };
type InputTrainingPlan = BaseProps &
  ComboBoxParentPropsSingle & { type: "trainingPlan" };
type InputTraining = BaseProps & TrainingPickerProps & { type: "training" };
type InputUpload = BaseProps & FileUploaderProps & { type: "upload" };
type InputUser = BaseProps & UserPickerPropsSingle & { type: "user" };
type InputUserMulti = BaseProps &
  Omit<UserPickerPropsMulti, "multiple"> & { type: "userMulti" };

export function Input(
  allProps:
    | InputAddress
    | InputAddressMulti
    | InputAffiliationType
    | InputAgreement
    | InputAuditTemplate
    | InputAuditTemplateItem
    | InputAuthorCombo
    | InputBillingPosition
    | InputButtonSelect
    | InputCampaignField
    | InputCampaignFieldMulti
    | InputCandidate
    | InputCheckbox
    | InputCombo
    | InputComboMulti
    | InputCommunity
    | InputCompany
    | InputContact
    | InputContactOrCompany
    | InputContentTopic
    | InputDate
    | InputDelayedText
    | InputDeliverable
    | InputDeposit
    | InputDispatch
    | InputModalDocument
    | InputDocument
    | InputDocumentCategory
    | InputDocumentCategoryMulti
    | InputElement
    | InputElementMulti
    | InputEmployee
    | InputFeature
    | InputFeeSchedule
    | InputFile
    | InputIssue
    | InputIssueMulti
    | InputKpiSet
    | InputJobTitle
    | InputLibrary
    | InputListItem
    | InputLocation
    | InputLocationMulti
    | InputMarketingStatus
    | InputMeeting
    | InputMembership
    | InputMilestoneParent
    | InputNumber
    | InputOffice
    | InputPayPeriod
    | InputPassword
    | InputPhoto
    | InputPhotoset
    | InputPhysicalAddress
    | InputPlanType
    | InputPlayer
    | InputPlayerMulti
    | InputPlayerGroup
    | InputPracticeArea
    | InputProject
    | InputProjectMulti
    | InputPtoPlan
    | InputQuarter
    | InputRetainerInvoice
    | InputSalesStage
    | InputSectionPicker
    | InputSkillPicker
    | InputSKUPicker
    | InputSocialMediaAccount
    | InputSocialMediaPost
    | InputSlate
    | InputState
    | InputTag
    | InputTagMulti
    | InputTagSection
    | InputTenTouchItem
    | InputTenTouchType
    | InputTenTouchTypeMulti
    | InputTestLocation
    | InputTestLocationMulti
    | InputText
    | InputTextarea
    | InputTime
    | InputTraining
    | InputTrainingCategory
    | InputTrainingCategoryMulti
    | InputTrainingPlan
    | InputUpload
    | InputUser
    | InputUserMulti
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const f = useContext(RemixFormContext);
  const {
    noHiddenField,
    wrapperStyle,
    wrapperClassName,
    help,
    helpAfter,
    noLabel,
    label,
    labels,
    buttons,
    inline,
    onChange,
    rightLabel,
    allowPasswordManagers = false,
    ...props
  } = allProps;

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...args: any[]) => {
      f?.setDirty(true);
      if (onChange) {
        // @ts-ignore
        onChange(...args);
      }
    },
    [f, onChange]
  );

  const defaultValue = _.get(f?.data || {}, props.name);

  let input: ReactNode;

  if (props.type === "address") {
    input = (
      <AddressPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "addressMulti") {
    input = (
      <AddressPicker
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "date") {
    input = (
      <DateField
        {...props}
        onChange={handleChange}
        value={props.value || defaultValue}
      />
    );
  } else if (props.type === "delayedText") {
    input = (
      <DelayedTextInput
        {...props}
        onChange={handleChange}
        value={props.value || defaultValue}
      />
    );
  } else if (props.type === "deliverable") {
    input = (
      <DeliverablePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "deposit") {
    input = (
      <DepositPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "dispatch") {
    input = (
      <DispatchPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "document") {
    input = (
      <DocumentPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "documentCategory") {
    input = (
      <DocumentCategoryPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "documentCategoryMulti") {
    input = (
      <DocumentCategoryPicker
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "element") {
    input = (
      <ElementPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "elementMulti") {
    input = (
      <ElementPicker
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "employee") {
    input = (
      <EmployeePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "issue") {
    input = (
      <IssuePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "issueMulti") {
    input = (
      <IssuePicker
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "location") {
    input = (
      <LocationPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "locationMulti") {
    input = (
      <LocationPicker
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "planType") {
    input = (
      <PlanTypePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "player") {
    input = (
      <PlayerPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "playerMulti") {
    input = (
      <PlayerPicker
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "playerGroup") {
    input = (
      <PlayerGroupPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "practiceArea") {
    input = (
      <PracticeAreaPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "retainerInvoice") {
    input = (
      <RetainerInvoicePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "skill") {
    input = (
      <SkillPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "testLocation") {
    input = (
      <TestingPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "testLocationMulti") {
    input = (
      <TestingPicker
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "time") {
    input = (
      <TimePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "trainingCategory") {
    input = (
      <TrainingCategoryPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "trainingCategoryMulti") {
    input = (
      <TrainingCategoryPicker
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "trainingPlan") {
    input = (
      <TrainingPlanPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "feature") {
    input = (
      <FeaturePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "feeSchedule") {
    input = (
      <FeeSchedulePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "kpiSet") {
    input = (
      <KpiSetPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "jobTitle") {
    input = (
      <JobTitlePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "library") {
    input = <LibrarySelect {...props} onChange={handleChange} />;
  } else if (props.type === "listItem") {
    input = (
      <ListItemPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "marketingStatus") {
    input = (
      <MarketingStatusPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "button-select") {
    input = (
      <div>
        <ButtonSelect
          {...props}
          onChange={handleChange}
          defaultValue={defaultValue}
        />
      </div>
    );
  } else if (props.type === "affiliationType") {
    input = (
      <AffiliationTypePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "agreement") {
    input = (
      <AgreementPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "auditTemplate") {
    input = (
      <AuditTemplatePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "auditTemplateItem") {
    input = (
      <AuditTemplateItemPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "campaignField") {
    input = (
      <CampaignFieldPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "campaignFieldMulti") {
    input = (
      <CampaignFieldPicker
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "candidate") {
    input = (
      <CandidatePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "community") {
    input = (
      <CommunityPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "contentTopic") {
    input = (
      <ContentTopicPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "contact") {
    input = <ContactPicker {...props} onChange={handleChange} mode="Contact" />;
  } else if (props.type === "company") {
    input = <ContactPicker {...props} onChange={handleChange} mode="Company" />;
  } else if (props.type === "contactOrCompany") {
    input = <ContactPicker {...props} onChange={handleChange} />;
  } else if (props.type === "modal-document") {
    input = <ModalDocumentPicker {...props} onChange={handleChange} />;
  } else if (props.type === "state") {
    input = (
      <StatePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "tag") {
    input = (
      <TagPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "tagMulti") {
    input = (
      <TagPicker
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "tagSection") {
    input = (
      <SectionPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "pto") {
    input = (
      <PTOPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "quarter") {
    input = <QuarterPicker {...props} onChange={handleChange} />;
  } else if (props.type === "salesStage") {
    input = (
      <SalesStagePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "fileSection") {
    input = (
      <SectionCombo
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "tenTouchType") {
    input = (
      <TenTouchTypePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "tenTouchTypeMulti") {
    input = (
      <TenTouchTypePicker
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "tenTouchItem") {
    input = (
      <TenTouchItemPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "combo") {
    input = (
      <ComboBox
        {...props}
        multiple={false}
        onChange={handleChange}
        defaultValue={props.value ? undefined : defaultValue}
      />
    );
  } else if (props.type === "combo-multi") {
    input = (
      <ComboBox
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={props.value ? undefined : defaultValue}
      />
    );
  } else if (props.type === "meeting") {
    input = (
      <MeetingPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "membership") {
    input = (
      <MembershipPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "milestoneParent") {
    input = (
      <MilestoneParentPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "number") {
    input = (
      <NumberInput
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "office") {
    input = (
      <OfficePicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "payPeriod") {
    input = (
      <PayPeriodPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "photo") {
    input = (
      <PhotoPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "photoset") {
    input = (
      <InspectionPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "physicalAddress") {
    input = (
      <PhysicalAddressPicker
        {...props}
        onChange={handleChange}
        value={Reflect.has(props, "value") ? props.value : defaultValue}
      />
    );
  } else if (props.type === "project") {
    input = (
      <ProjectPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "projectMulti") {
    input = (
      <ProjectPicker
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "user") {
    input = (
      <UserPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "userMulti") {
    input = (
      <UserPicker
        {...props}
        multiple
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "fileAuthor") {
    input = (
      <AuthorCombo
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "billingPosition") {
    input = (
      <BillingPositionPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "training") {
    input = (
      <TrainingPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "sku") {
    input = (
      <SKUPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "socialMediaAccount") {
    input = (
      <SocialMediaAccountPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "socialMediaPost") {
    input = (
      <SocialMediaPostPicker
        {...props}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else if (props.type === "file") {
    input = (
      <FilePicker
        key={props.inputKey || "1"}
        onChange={handleChange}
        {...props}
      />
    );
  } else if (props.type === "upload") {
    input = <FileUploader onChange={handleChange} {...props} />;
  } else if (props.type === "slate") {
    input = (
      <Slate
        key={props.inputKey || "1"}
        {...props}
        value={Reflect.has(props, "value") ? props.value : defaultValue}
        onChange={handleChange}
      />
    );
  } else if (props.type === "checkbox") {
    input = (
      <>
        {!noHiddenField && <input type="hidden" name={props.name} value="0" />}
        <input
          value="1"
          onChange={handleChange}
          className={inline ? "top-[1px]" : undefined}
          defaultChecked={
            Reflect.has(props, "checked") ? undefined : defaultValue
          }
          {...props}
        />
      </>
    );
  } else if (props.type === "textarea") {
    const { className, ...rest } = props;
    input = (
      <WrappedTextarea
        className={clsx("form-control", className)}
        style={{ resize: "none" }}
        minRows={2}
        onChange={handleChange}
        forwardRef={
          props.forwardRef as React.MutableRefObject<HTMLTextAreaElement>
        }
        defaultValue={Reflect.has(props, "value") ? undefined : defaultValue}
        {...rest}
      />
    );
  } else {
    const { className, forwardRef, type, ...rest } = props;
    input = (
      <input
        className={clsx("form-control", className)}
        onChange={handleChange}
        defaultValue={Reflect.has(props, "value") ? undefined : defaultValue}
        ref={forwardRef as React.MutableRefObject<HTMLInputElement>}
        type={type || "text"}
        data-1p-ignore={!allowPasswordManagers}
        data-lpignore={!allowPasswordManagers}
        {...rest}
      />
    );
  }

  input =
    labels || buttons ? (
      <InputGroup labels={labels} buttons={buttons}>
        {input}
      </InputGroup>
    ) : (
      input
    );

  const formattedLabel =
    label ?? _.startCase(props.name).replace(/\s(Id|Slate)$/, "");

  if (props.type === "checkbox") {
    return (
      <div className={clsx("checkbox", wrapperClassName)} style={wrapperStyle}>
        <label>
          {input} {formattedLabel}
        </label>
      </div>
    );
  }

  if (inline) {
    return (
      <>
        {!noLabel && <label htmlFor={props.name}>{formattedLabel}</label>}
        {help && <div className="help-block">{help}</div>}
        {input}
        {helpAfter && (
          <div className="help-block" style={{}}>
            {helpAfter}
          </div>
        )}
      </>
    );
  }
  return (
    <div
      className={clsx(
        "form-group",
        f?.fieldErrors?.[props.name] && "has-error",
        wrapperClassName
      )}
      style={wrapperStyle}
    >
      {!!rightLabel && <div className="float-right">{rightLabel}</div>}
      {!noLabel && <label htmlFor={props.name}>{formattedLabel}</label>}
      {help && <div className="help-block">{help}</div>}
      {input}
      {helpAfter && (
        <div className="help-block" style={{}}>
          {helpAfter}
        </div>
      )}
    </div>
  );
}
