import type { Location } from "react-router-dom";
import type {
  ContentTopicContextType,
  EmployeeContextType,
  ProjectContextType
} from "~/contexts";
import type {
  Document,
  AttachedFileFieldsFragment,
  DocumentFieldsFragment,
  DocumentFullFieldsFragment,
  DocumentRowFieldsFragment,
  DocumentShowFieldsFragment,
  SourceFileFieldsFragment,
  DocumentMd5Query,
  DocumentRowFieldsWithSourceFragment
} from "~/types/api";
import _ from "lodash";
import { getFileHash } from "~/utils/md5";
import { createURLSearchParams } from "~/utils/remix";

const FILE_TYPES: { [key: string]: string } = {
  "3GP": "Video",
  "3G2": "Video",
  DOC: "Word",
  DOCX: "Word",
  HEIC: "Image (HEIC)",
  HEIF: "Image (HEIF)",
  JPEG: "Image",
  JPG: "Image",
  MOV: "Video",
  MP3: "Audio",
  MP4: "Video",
  MSG: "Outlook Email File",
  PDF: "PDF",
  PNG: "Image",
  PPT: "PowerPoint",
  PPTX: "PowerPoint",
  PST: "Outlook Email Archive",
  TXT: "Text File",
  XLS: "Excel",
  XLSX: "Excel",
  ZIP: "Zip"
};

export const getFileType = (fileName?: string | null) => {
  if (fileName) {
    const ext = fileName.split(".").pop()!.toUpperCase();
    return FILE_TYPES[ext] || ext;
  }
  return null;
};

export const getPrimaryFile = (
  document?:
    | DocumentShowFieldsFragment
    | DocumentFullFieldsFragment
    | SourceFileFieldsFragment
    | DocumentRowFieldsFragment
    | DocumentFieldsFragment
    | { attachedFiles: AttachedFileFieldsFragment[] }
    | null,
  opts: { forceSourceFile?: boolean } = {}
): AttachedFileFieldsFragment | undefined => {
  if (!document) return undefined;
  const attachedFiles = document.attachedFiles || [];
  const primary = attachedFiles.find((af) => af.primaryFile);
  if ("referenceDocument" in document && document.referenceDocument) {
    return getPrimaryFile(document.referenceDocument);
  } else if (
    (!primary || opts.forceSourceFile) &&
    "sourceFile" in document &&
    document.__typename === "Document" &&
    document.sourceFile
  ) {
    return getPrimaryFile(document.sourceFile);
  }
  return primary;
};

export const getSection = (document: Pick<Document, "section">): string => {
  return document.section || "";
};

/**
 * Provides a default sort for files. The list may still group/sort beyond this,
 * but within each section this will be the default sort.
 */
export const sortFiles = (files: DocumentRowFieldsFragment[]) =>
  files &&
  _.sortBy(files, [
    (d) => !!d.section,
    "section",
    (d) => d.mode !== "Header",
    (d) => (d.mode === "Folder" ? [d.date || "z", d.author] : ["z", "zzz"]),
    // d => (d.document?.mode === "Folder" ? d.document?.author : "zzz"),
    (d) => d.mode !== "Folder",
    "suffix",
    (d) => (d.referenceDocument || d).date,
    (d) => (d.referenceDocument || d).author,
    (d) => !!(d.referenceDocument || d).description,
    (d) => {
      const doc = d.referenceDocument || d;
      if (doc.description) return doc.description;
      const af = getPrimaryFile(doc);
      if (af?.upload) {
        return af.upload.filename.replace(/_/g, " ");
      }
      return "";
    },
    "id"
  ]);

export const getDocLink = (
  document:
    | DocumentFullFieldsFragment
    | DocumentFieldsFragment
    | DocumentRowFieldsWithSourceFragment,
  location?: Location
) => {
  const path = !("project" in document)
    ? document.id
    : document.project
      ? `/projects/${document.project.number}/files/${document.id}`
      : document.employee
        ? document.employeeScope === "Employee"
          ? `/me/files/${document.id}`
          : `/employees/${document.employee.id}/files/${document.id}`
        : document.contentTopic
          ? `/content/${document.contentTopic.id}/files/${document.id}`
          : `/library/files/${document.id}`;
  // Append search for the redirect
  if (location?.pathname.endsWith("/files") && location?.search) {
    return `${path}?redirectTo=${location.pathname}${location.search}`;
  }
  return path;
};

export const isFile = (document: { mode: string }) =>
  ["File", "Reference"].includes(document.mode);

export const checkDuplicateHashes = async (
  file: File,
  {
    project,
    employee,
    contentTopic
  }: {
    project: ProjectContextType;
    employee: EmployeeContextType;
    contentTopic: ContentTopicContextType;
  }
) => {
  const hash = await getFileHash(file);
  const search = createURLSearchParams({
    xxHash: hash,
    projectId: project?.id,
    employeeId: employee?.id,
    employeeScope: employee?.scope,
    contentTopicId: contentTopic?.id
  }).toString();
  const result = await fetch(`/resources/documents/md5?${search}`);
  const json = (await result.json()) as DocumentMd5Query;

  if (!json.documents.length) return null;

  return project
    ? `/projects/${project.number}/files/${json.documents[0].id}`
    : contentTopic
      ? `/content/${contentTopic.id}/files/${json.documents[0].id}`
      : employee
        ? employee.scope === "Employee"
          ? `/me/files/${json.documents[0].id}`
          : `/employees/${employee.id}/files/${json.documents[0].id}`
        : `/library/files/${json.documents[0].id}`;
};
