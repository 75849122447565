import type { FilesViewMode } from "~/components/documents/toolbar";
import type {
  DocumentFieldsFragment,
  DocumentRowFieldsFragment,
  DocumentRowFieldsWithSourceFragment,
  ParentDocumentFieldsFragment
} from "~/types/api";
import _ from "lodash";
import React, { useMemo, useState, Fragment, useCallback } from "react";
import Viewer from "~/components/documents/form-viewer";
import DocumentListHeader from "~/components/documents/list-header";
import DocumentRow from "~/components/documents/row";
import Thumbs from "~/components/documents/thumbs";
import { useLocationPusher } from "~/components/link";
import NumberedTable from "~/components/numbered-table";
import { useOptionalProject } from "~/contexts";
import { sortFiles, getPrimaryFile } from "~/utils/documents";
import { normalizedNumber, plural } from "~/utils/formatting";
import { slateIsBlank } from "~/utils/text";

export type DocRowType =
  | DocumentRowFieldsFragment
  | DocumentRowFieldsWithSourceFragment;

interface Props {
  descriptionField?: boolean;
  disabledIds?: string[];
  documents: DocRowType[];
  editable?: boolean;
  expandAll?: boolean;
  isFiltered?: boolean;
  library?: boolean;
  minimal?: boolean;
  addLabel?: string;
  nested?: boolean;
  onAdd?: (document: DocRowType) => void;
  onDelete?: (id: string) => void;
  onMove?: (id: string, direction: "up" | "down") => void;
  ordered?: boolean;
  page?: number;
  pages?: number;
  parent?: ParentDocumentFieldsFragment;
  parentId?: string;
  redirectFolders?: boolean;
  selectedIds?: string[];
  setSelectedIds?: (ids: string[]) => void;
  setViewOptions?: React.Dispatch<
    React.SetStateAction<Partial<FileViewOptions>>
  >;
  totalCount?: number;
  viewOptions?: Partial<FileViewOptions>;
}

const fieldsToGroup = ["author", "receivedDate", "md5", "analysis"];

const isKey = (d: DocRowType, documents: DocRowType[]): boolean => {
  return (
    d.key ||
    documents
      .filter((dd) => dd.document?.id === d.id)
      .some((dd) => isKey(dd, documents))
  );
};

export type FileViewOptions = {
  fileType?: boolean;
  hidden: string[];
  hideSections?: boolean;
  hideSummaries?: boolean;
  hideUploads?: boolean;
  includeSource?: boolean;
  key?: boolean;
  mode: FilesViewMode;
  pdfWarning?: boolean;
  sectionWarning?: boolean;
  sort: string;
  sortDirection: "asc" | "desc";
};

export default function DocumentList({
  addLabel,
  descriptionField,
  disabledIds = [],
  documents,
  editable,
  expandAll,
  isFiltered,
  library,
  minimal,
  nested,
  onAdd,
  onDelete,
  onMove,
  ordered,
  page = 1,
  pages,
  parent,
  parentId,
  redirectFolders,
  selectedIds = [],
  setSelectedIds,
  setViewOptions: setParentViewOptions,
  totalCount,
  viewOptions: parentViewOptions
}: Props) {
  const sorted = useMemo(
    () => (ordered ? documents : sortFiles(documents)),
    [documents, ordered]
  );
  const push = useLocationPusher();
  const project = useOptionalProject();
  const [viewerId, setViewerId] = useState("");

  const [_viewOptions, _setViewOptions] = useState<FileViewOptions>({
    mode: "list",
    hidden: sorted.filter((d) => d.mode === "Folder").map((d) => d.id),
    sort: "section",
    sortDirection: "asc"
  });

  const viewOptions = useMemo(
    () => ({ ..._viewOptions, ...(parentViewOptions || {}) }),
    [_viewOptions, parentViewOptions]
  );

  const setViewOptions = setParentViewOptions || _setViewOptions;
  const filtered = useMemo(() => {
    let filtered = sorted;
    if (viewOptions.key) {
      filtered = filtered.filter((d) => isKey(d, documents));
    }
    return filtered;
  }, [documents, sorted, viewOptions.key]);

  const setSort = (value: string) => {
    if (library) {
      push({ sort: value }, { replace: true });
    } else {
      setViewOptions(
        viewOptions.sort === value
          ? {
              ...viewOptions,
              sortDirection:
                viewOptions.sortDirection === "asc" ? "desc" : "asc"
            }
          : { ...viewOptions, sort: value, sortDirection: "asc" }
      );
    }
  };

  const hasBates = documents.some(
    (d) => d.referenceDocument?.batesStamp || d.batesStamp
  );

  const withoutSections = filtered.filter((d) => !d.section);

  const onSelect = useMemo(
    () =>
      setSelectedIds
        ? (documentId: string, checked?: boolean) => {
            if (documentId === "all") {
              const selectableDocs = documents.filter((d) =>
                ["File", "Reference"].includes(d.mode)
              );
              setSelectedIds(
                selectedIds.length === selectableDocs.length
                  ? []
                  : selectableDocs.map((d) => d.id)
              );
            } else {
              setSelectedIds(
                checked
                  ? [...selectedIds, documentId]
                  : selectedIds.filter((id) => id !== documentId)
              );
            }
          }
        : undefined,
    [documents, selectedIds, setSelectedIds]
  );
  const onMoveUp = useMemo(
    () => onMove && ((id: string) => onMove(id, "up")),
    [onMove]
  );
  const onMoveDown = useMemo(
    () => onMove && ((id: string) => onMove(id, "down")),
    [onMove]
  );

  const toggleHidden = useCallback(
    (documentId: string) =>
      setViewOptions(
        viewOptions.hidden.includes(documentId)
          ? {
              ...viewOptions,
              hidden: viewOptions.hidden.filter((id) => id !== documentId)
            }
          : { ...viewOptions, hidden: [...viewOptions.hidden, documentId] }
      ),
    [setViewOptions, viewOptions]
  );
  const rows = useMemo(() => {
    const getCounts = (d: DocRowType) => {
      if (isFiltered && ["Folder", "Header"].includes(d.mode)) {
        const nested = filtered.filter((child) =>
          child.path.startsWith(`${d.path}.`)
        );
        const files = nested.filter((child) =>
          ["File", "Reference"].includes(child.mode)
        );
        return {
          files: files.length,
          folders: nested.filter((child) => child.mode === "Folder").length,
          pages: files.reduce((acc, child) => acc + (child.pages || 0), 0)
        };
      }
      return undefined;
    };
    const renderDoc = (d: DocRowType, index: number, nested = true) => (
      <Fragment key={d.id}>
        <DocumentRow
          document={d}
          editable={editable}
          disabled={disabledIds.includes(d.id)}
          selected={selectedIds.includes(d.id)}
          library={library}
          onSelect={onSelect}
          pdfWarning={viewOptions.pdfWarning}
          minimal={minimal}
          hasBates={hasBates}
          onDelete={onDelete}
          onMoveUp={index > 0 ? onMoveUp : undefined}
          onMoveDown={index < documents.length - 1 ? onMoveDown : undefined}
          descriptionField={descriptionField}
          section={!viewOptions.hideSections}
          includeSource={viewOptions.includeSource}
          includeThumb={viewOptions.mode === "thumblist"}
          toggleHidden={nested ? toggleHidden : undefined}
          hideSummary={viewOptions.hideSummaries}
          nested={nested}
          onAdd={onAdd}
          addLabel={addLabel}
          redirectFolders={redirectFolders}
          fileType={viewOptions.fileType}
          viewing={d.id === viewerId}
          onView={viewOptions.mode === "viewer" ? setViewerId : undefined}
          counts={getCounts(d)}
        />
        {nested &&
          !parentId &&
          (expandAll || !viewOptions.hidden.includes(d.id)) &&
          filtered
            .filter((s) => s.document?.id === d.id)
            .map((d, i) => renderDoc(d, i))}
      </Fragment>
    );

    if (viewOptions.sort !== "section") {
      const groups = _.groupBy(
        filtered.filter((d) =>
          ["File", "Reference", "Folder"].includes(d.mode)
        ),
        (d) => {
          if (viewOptions.sort === "description") {
            const desc =
              d.referenceDocument?.description || d.mode === "Folder"
                ? d.author
                : d.description;
            if (desc) {
              return desc;
            }
            const af = getPrimaryFile(d.referenceDocument || d);
            if (af?.upload) {
              return af.upload.filename.replace(/_/g, " ");
            }
            return "zzz";
          }
          if (viewOptions.sort === "analysis") {
            return d.analysisStatus === "Internal" &&
              slateIsBlank(d.analysisSlate)
              ? "No Analysis"
              : d.analysisStatus;
          }
          return viewOptions.sort !== "section" && d.referenceDocument
            ? d.referenceDocument[
                viewOptions.sort as keyof DocumentFieldsFragment
              ] || ""
            : d[viewOptions.sort as keyof DocRowType] || "";
        }
      );

      const keys = _.orderBy(
        Object.keys(groups).map((k) => ({
          key: k,
          sort:
            viewOptions.sort === "pages"
              ? parseInt(k, 10) || ""
              : viewOptions.sort === "analysis"
                ? [
                    "Internal",
                    "Needs Peer Review",
                    "Published",
                    "No Analysis"
                  ].indexOf(k)
                : viewOptions.sort === "batesStamp"
                  ? normalizedNumber(k) || ""
                  : k
        })),
        (k) => k.sort,
        [viewOptions.sortDirection]
      );

      return keys.map(({ key }) => (
        <Fragment key={key}>
          {fieldsToGroup.includes(viewOptions.sort) && (
            <tr className="success font-bold">
              <td />
              <td />
              <td />
              <td />
              <td colSpan={99}>{key || "N/A"}</td>
            </tr>
          )}
          {groups[key].map((d) => renderDoc(d, 0, false))}
        </Fragment>
      ));
    }

    return nested
      ? filtered.filter((d) => !d.document).map((d, i) => renderDoc(d, i))
      : filtered.map((d, i) => renderDoc(d, i, false));
  }, [
    descriptionField,
    disabledIds,
    documents.length,
    editable,
    expandAll,
    filtered,
    hasBates,
    isFiltered,
    library,
    minimal,
    nested,
    onAdd,
    addLabel,
    onDelete,
    onMoveDown,
    onMoveUp,
    onSelect,
    parentId,
    redirectFolders,
    selectedIds,
    toggleHidden,
    viewerId,
    viewOptions
  ]);

  const fileCount = useMemo(() => {
    if (totalCount) {
      return totalCount;
    }
    if (isFiltered) {
      return filtered.filter((d) => ["File", "Reference"].includes(d.mode))
        .length;
    }
    if (nested) {
      return filtered.reduce(
        (sum, d) =>
          sum +
          (d.mode === "Header" || (d.mode === "Folder" && !d.document)
            ? d.fileCount
            : !d.document
              ? 1
              : 0),
        0
      );
    }
    return filtered.filter((d) => ["File", "Reference"].includes(d.mode))
      .length;
  }, [filtered, isFiltered, nested, totalCount]);

  const pageCount = useMemo(() => {
    if (pages) {
      return pages;
    }
    // if (parentId) {
    //   return filtered.find((f) => f.id === parentId)!.pageCount;
    // }
    if (isFiltered) {
      return filtered.reduce(
        (sum, d) =>
          sum + (["File", "Reference"].includes(d.mode) ? d.pages || 0 : 0),
        0
      );
    }
    if (nested) {
      return filtered.reduce(
        (sum, d) =>
          sum +
          (d.mode === "Header" || (d.mode === "Folder" && !d.document)
            ? d.pageCount
            : !d.document
              ? d.pages || 0
              : 0),
        0
      );
    }
    return filtered.reduce((acc, d) => acc + (d.pages || 0), 0);
  }, [filtered, isFiltered, nested, pages]);

  if (viewOptions.mode === "thumbs") {
    return <Thumbs documents={filtered} parent={parent} library={library} />;
  }
  const table = (
    <NumberedTable onSort={setSort} offset={(page - 1) * 100}>
      <DocumentListHeader
        sortable
        project={project}
        includeSource={viewOptions.includeSource}
        fileType={viewOptions.fileType}
        hasBates={hasBates && !minimal}
        hideSummary={viewOptions.hideSummaries}
        minimal={minimal}
        includeThumb={viewOptions.mode === "thumblist"}
        section={!viewOptions.hideSections}
        onSelect={onSelect}
        allSelected={
          selectedIds.length > 0 && selectedIds.length === documents.length
        }
      />
      <tbody>
        {(project || documents.some((d) => d.mode === "Header")) &&
          !parentId &&
          viewOptions.sectionWarning &&
          withoutSections.length > 0 &&
          viewOptions.sort === "section" &&
          viewOptions.sortDirection === "asc" && (
            <tr className="text-danger danger font-bold">
              <td />
              <td />
              <td className="text-center">N/A</td>
              <td colSpan={viewOptions.fileType ? 3 : 2} />
              <td colSpan={hasBates ? 5 : 4}>
                <div className="flex justify-between space-x-2">
                  <div>
                    These files need to be indexed into their appropriate
                    sections.{" "}
                    {project?.billable &&
                      "They will be hidden on Client Access until indexed."}
                  </div>
                  <div>
                    {plural("File", withoutSections.length, true)} &bull;{" "}
                    {plural(
                      "Page",
                      withoutSections.reduce(
                        (acc, d) => acc + (d.pages || 0),
                        0
                      ),
                      true
                    )}
                  </div>
                </div>
              </td>
              <td colSpan={99} />
            </tr>
          )}
        {rows}
      </tbody>
      <tfoot>
        <tr className="success font-bold">
          {minimal ? (
            <>
              <td
                colSpan={
                  8 +
                  (viewOptions.fileType ? 1 : 0) +
                  (viewOptions.hideSections ? -1 : 0) +
                  (viewOptions.includeSource ? 1 : 0)
                }
                className="text-right"
              >
                {plural("File", fileCount, true)} &bull;{" "}
                {plural("Pages", pageCount, true)}
              </td>
              <td colSpan={99} />
            </>
          ) : (
            <>
              <td
                colSpan={
                  9 +
                  (hasBates ? 1 : 0) +
                  (viewOptions.fileType ? 1 : 0) +
                  (viewOptions.includeSource ? 1 : 0) +
                  (viewOptions.hideSections ? -1 : 0) +
                  (viewOptions.mode === "thumblist" ? 1 : 0)
                }
                className="text-right"
              >
                {plural("File", fileCount, true)} &bull;{" "}
                {plural("Pages", pageCount, true)}
              </td>
              <td colSpan={99} />
            </>
          )}
        </tr>
      </tfoot>
    </NumberedTable>
  );

  if (viewOptions.mode === "viewer") {
    return (
      <div className="flex flex-wrap space-x-8">
        <div className="flex-1">{table}</div>

        <div className="flex-1" style={{ minWidth: "500px" }}>
          <Viewer
            noMargin
            noFileWarning="Click a file on the left to view"
            file={documents.find((d) => d.id === viewerId)}
          />
        </div>
      </div>
    );
  }

  return table;
}
