import type { CSSProperties } from "react";
import clsx from "clsx";
import _ from "lodash";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { IconCalendar, IconLeft, IconRight, IconX } from "~/components/icons";
import InputGroup from "~/components/input-group";
import Link from "~/components/link";
import {
  addDays,
  addMonths,
  dayIsSame,
  endOfMonth,
  formatDate,
  parseDate,
  parseDateOrNull,
  startOfMonth,
  weeksInRange
} from "~/utils/dates";

export interface DateFieldProps {
  name: string;
  className?: string;
  value?: string;
  onChange?: (name: string, value: string) => void;
  placeholder?: string;
  icon?: boolean;
  style?: CSSProperties;
  isClearable?: boolean;
  align?: "left" | "right";
  disabled?: boolean;
}

export default function DateField({
  name,
  className,
  value,
  onChange,
  placeholder = "Select a date...",
  icon,
  style,
  isClearable = true,
  disabled,
  align = "left"
}: DateFieldProps) {
  const date = _.isDate(value) ? value : value ? parseDateOrNull(value) : null;
  const formatted = date ? formatDate(date) : "";
  useEffect(() => {
    setText(formatted);
  }, [formatted]);
  const ref = useRef<HTMLInputElement>(null);
  const [text, setText] = useState(formatted);
  const [show, setShow] = useState(false);

  const parseAndPushDate = () => {
    const date = parseDateOrNull(text);
    if (date) {
      const val = formatDate(date, { format: "YYYY-MM-DD" });
      setText(formatDate(date));
      if (value !== val) {
        onChange?.(name, val);
      }
    } else {
      setText("");
      if (value !== "") {
        onChange?.(name, "");
      }
    }
    setShow(false);
  };

  const handleKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "Enter": {
        ref.current?.blur();
        event.preventDefault();
        break;
      }
      case "Backspace": {
        if (isClearable) {
          setText("");
          onChange?.(name, "");
        }
        event.preventDefault();
        break;
      }
      case "t":
      case "T": {
        const next = new Date();
        setText(formatDate(next));
        onChange?.(name, formatDate(next, { format: "YYYY-MM-DD" }));
        event.preventDefault();
        break;
      }
      case "ArrowUp": {
        const d = parseDateOrNull(text) || new Date();
        const next = addDays(d, -7);
        setText(formatDate(next));
        onChange?.(name, formatDate(next, { format: "YYYY-MM-DD" }));
        event.preventDefault();
        break;
      }
      case "ArrowDown": {
        const d = parseDateOrNull(text) || new Date();
        const next = addDays(d, 7);
        setText(formatDate(next));
        onChange?.(name, formatDate(next, { format: "YYYY-MM-DD" }));
        event.preventDefault();
        break;
      }
      case "ArrowLeft":
      case "-": {
        const d = parseDateOrNull(text) || new Date();
        const next = addDays(d, -1);
        setText(formatDate(next));
        onChange?.(name, formatDate(next, { format: "YYYY-MM-DD" }));
        event.preventDefault();
        break;
      }
      case "ArrowRight":
      case "+":
      case "=": {
        const d = parseDateOrNull(text) || new Date();
        const next = addDays(d, 1);
        setText(formatDate(next));
        onChange?.(name, formatDate(next, { format: "YYYY-MM-DD" }));
        event.preventDefault();
        break;
      }
      default:
    }
  };

  const handleSelect = useCallback(
    (date: Date) => {
      ref.current?.blur();
      setText(formatDate(date));
      onChange?.(name, formatDate(date, { format: "YYYY-MM-DD" }));
    },
    [name, onChange]
  );

  const field = (
    <>
      {isClearable && text && (
        <div
          className="absolute inset-y-0 right-0 z-10 flex cursor-pointer items-center pr-3 text-gray-400"
          onClick={() => {
            setText("");
            onChange?.(name, "");
          }}
        >
          <IconX />
        </div>
      )}
      {show && (
        <DatePickerCalendar
          value={text}
          icon={icon}
          onSelect={handleSelect}
          align={align}
        />
      )}
      <input
        ref={ref}
        name={name}
        className={clsx(className, "form-control")}
        type="text"
        autoComplete="off"
        value={text}
        onChange={(e) => setText(e.target.value)}
        onBlur={parseAndPushDate}
        onFocus={() => setShow(true)}
        onKeyDown={handleKey}
        placeholder={placeholder}
        style={style}
        disabled={disabled}
      />
    </>
  );
  return icon ? (
    <InputGroup
      className="relative"
      labels={
        <IconCalendar
          className="-mx-[12px] -my-[4px] cursor-pointer px-[12px] py-[4px]"
          onClick={() => ref.current?.focus()}
        />
      }
    >
      {field}
    </InputGroup>
  ) : (
    <div className="relative">{field}</div>
  );
}

type CalendarProps = {
  value: string;
  icon?: boolean;
  onSelect: (date: Date) => void;
  align?: "left" | "right";
};

export const DatePickerCalendar = ({
  value,
  icon,
  onSelect,
  align = "left"
}: CalendarProps) => {
  const date = useMemo(
    () => (value ? parseDateOrNull(value) || new Date() : new Date()),
    [value]
  );

  const [[month, monthDate], setMonth] = useState(() => {
    const d = startOfMonth(date);
    return [formatDate(startOfMonth(date), { format: "YYYY-MM" }), d];
  });

  useEffect(() => {
    setMonth([formatDate(startOfMonth(date), { format: "YYYY-MM" }), date]);
  }, [date]);

  const weeks = useMemo(() => {
    return weeksInRange(startOfMonth(month), endOfMonth(month));
  }, [month]);

  const prev = () => {
    const d = addMonths(parseDate(month), -1);
    setMonth([formatDate(d, { format: "YYYY-MM" }), d]);
  };
  const next = () => {
    const d = addMonths(parseDate(month), 1);
    setMonth([formatDate(d, { format: "YYYY-MM" }), d]);
  };

  return (
    <div
      className={clsx(
        icon && "ml-16",
        "absolute top-0 z-200 mt-12 rounded-lg border border-gray-100 bg-white p-4 shadow",
        align === "left" ? "left-0" : "right-0"
      )}
      style={{ width: 274 }}
      onClick={(e) => e.preventDefault()}
      onMouseDown={(e) => e.preventDefault()}
    >
      <div className="mb-4 flex items-center justify-between px-3">
        <div>
          <span style={{ fontSize: 14 }} className="font-bold text-gray-800">
            {formatDate(monthDate, { format: "MMMM" })}
          </span>
          <span
            style={{ fontSize: 14 }}
            className="ml-2 font-normal text-gray-600"
          >
            {monthDate.getFullYear()}
          </span>
        </div>
        <div className="flex space-x-4">
          <Link
            tabIndex={-1}
            to={prev}
            style={{ fontSize: 14 }}
            className="text-gray-700"
          >
            <IconLeft />
          </Link>
          <Link
            tabIndex={-1}
            to={next}
            style={{ fontSize: 14 }}
            className="text-gray-700"
          >
            <IconRight />
          </Link>
        </div>
      </div>
      <div className="mb-3 grid grid-cols-7 text-center">
        <span>Sun</span>
        <span>Mon</span>
        <span>Tue</span>
        <span>Wed</span>
        <span>Thu</span>
        <span>Fri</span>
        <span>Sat</span>
      </div>
      <div className="mb-1 grid grid-cols-7 text-center">
        {weeks.map((week) => (
          <Fragment key={`week-${week[0].getTime()}`}>
            {_.range(0, 7).map((r) => {
              const d = addDays(week[0], r);
              const selected = value && dayIsSame(date, d);
              const today = dayIsSame(new Date(), d);
              return (
                <span
                  style={{ height: 31 }}
                  className={clsx(
                    "m-1 flex cursor-pointer items-center justify-center rounded-full p-1",
                    selected
                      ? "bg-blue-200 text-blue-600"
                      : "duration-25 ease-in-out hover:bg-gray-100",
                    today && "border border-blue-200",
                    d.getMonth() !== monthDate.getMonth()
                      ? "text-gray-400"
                      : "text-gray-700"
                  )}
                  onClick={() => onSelect(d)}
                  key={d.getTime()}
                >
                  {d.getDate()}
                </span>
              );
            })}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
