import type { DocumentRowFieldsWithSourceFragment } from "~/types/api";
import clsx from "clsx";
import { useState } from "react";
import Button from "~/components/button";
import ModalDocumentChooser from "~/components/documents/modal-chooser";
import { IconEdit, IconTrash } from "~/components/icons";
import Link from "~/components/link";
import { getDocLink } from "~/utils/documents";

export type ModalDocumentPickerProps = {
  name: string;
  value?: DocumentRowFieldsWithSourceFragment | null;
  onChange?: (name: string, value: string, extra?: unknown) => void;
  socialMedia?: boolean;
  isClearable?: boolean;
};

export default function ModalDocumentPicker({
  value,
  socialMedia,
  onChange,
  name,
  isClearable = true,
  ...rest
}: ModalDocumentPickerProps) {
  const [adding, setAdding] = useState(false);
  const [localValue, setLocalValue] = useState<
    DocumentRowFieldsWithSourceFragment | null | undefined
  >(value);
  const handleChange = (value: unknown) => {
    const val = value as DocumentRowFieldsWithSourceFragment;
    setLocalValue(val);
    onChange?.(name, val.id);
    setAdding(false);
  };
  const link = localValue ? getDocLink(localValue) : undefined;
  return (
    <div>
      <input type="hidden" name={name} value={localValue?.id || ""} />
      <div className="flex">
        <div
          className={clsx(
            "form-control flex-1",
            value ? "truncate" : "cursor-pointer"
          )}
          style={{
            padding: "4px 6px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
          onClick={localValue ? undefined : () => setAdding(true)}
        >
          {localValue ? (
            <Link to={link} target="_blank">
              {localValue?.displayName}
            </Link>
          ) : (
            "Select a Document..."
          )}
        </div>
        {localValue && isClearable && (
          <Button
            small
            onClick={() => {
              setLocalValue(null);
              onChange?.(name, "");
            }}
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              marginLeft: -1
            }}
          >
            <IconTrash /> Remove
          </Button>
        )}
        <Button
          small
          onClick={() => setAdding(true)}
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            marginLeft: -1
          }}
        >
          <IconEdit /> {localValue ? "Change" : "Choose"}
        </Button>
      </div>
      {adding && (
        <ModalDocumentChooser
          onAdd={handleChange}
          onClose={() => setAdding(false)}
          addLabel="Select"
        />
      )}
    </div>
  );
}
