import type { CSSProperties, ReactNode } from "react";
import LinkifyJS from "linkify-react";
import React from "react";

const opts = { target: "_blank" };

type Props = {
  lineBreaks?: boolean;
  children: ReactNode;
  style?: CSSProperties;
};

export default function Linkify({ children, lineBreaks, style }: Props) {
  return lineBreaks ? (
    <LinkifyJS
      options={opts}
      tagName="div"
      className="auto-line-breaks"
      style={style}
    >
      {children}
    </LinkifyJS>
  ) : (
    <LinkifyJS options={opts}>{children}</LinkifyJS>
  );
}
